


























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const sumValues = (array: any) => {
      const value = array.reduce((accumulator: any, object: any) => {
        return accumulator + object.answersCount;
      }, 0);

      return value;
    };

    const print = () => {
      const node: any = document.querySelector(".print");

      const mywindow: any = window.open("", "PRINT", "height=800,width=1000");

      mywindow.document.write(
        `<html>
        <head>
        <title>${props.item.name}</title>
        <style>
        @media print {
          .no-print, button, .v-input {
            display: none;
          }
          div {
            border-left: none !important;
          }
          .h6 {
            font-size: 24px;
            font-weight: 700;
          }
          table tr th,
          table tr td {
            padding: 10px;
            border-bottom: 1px solid black;
          }
          .text-subtitle-2 {
            font-size: 16px;
            font-weight: 700;
          }
          .v-card__text {
            margin-top: 15px;
          }
          .d-flex {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
          }
        }
        </style>
        `
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write(node.innerHTML);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();

      setTimeout(mywindow.print(), 1000);
      mywindow.close();
    };
    return { sumValues, print };
  },
});
